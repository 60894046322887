@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;900&display=swap');
@import '~antd/dist/antd.less';

body {
  font-family: "Mulish", Helvetica, "Helvetica Neue", Arial, sans-serif;
}

#menu-bar .logo {
  float: left;
  width: 150px;
  height: 60px;
  margin-left: 16px;
  font-size: 200%;
}
#menu-bar .logo img{
  width: 130px;
  margin: 10px 5px 0 0;
  padding: 0;
  float: left;
}
.ant-layout-header {
  padding: 0 15px;
}


.site-layout-content {
  background: #fff;
  padding: 24px;
  min-height: 280px;
}

#menu-bar {
  background-image: linear-gradient(260deg, #2e3da9 0%, #1035a9 100%);
}

#menu-bar .ant-menu-root {
  background-image: linear-gradient(260deg, #2e3da9 0%, #1035a9 100%);
}


#site-layout {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#site-content {
  flex: 1;
  margin: 16px;
  padding: 24px;
  background: #fff;
  min-height: 300px;
}

#site-footer {
  color: white;
  text-align: center;
  padding: 10px;
  /*background-image: linear-gradient(260deg, #be2a80 0%, #9f2269 100%);*/
  background-image: linear-gradient(260deg, #2a871f 0%, #1e6617 100%);
}

h1.ant-typography {
  font-weight: 300 !important;
}

h2.ant-typography {
  font-weight: 300 !important;
}

h3.ant-typography {
  font-weight: 300 !important;
  font-size: 120% !important;
}

h1.ant-typography.page-header {
  font-weight: 300;
}

.btn-success {
  background-color: #7ecf7b !important;
  /*color: white;*/
}

/* Helper methods */
.p-0 {
  padding: 0 !important;
}

.p-0 .ant-card-body {
  padding: 0;
}

.p-5 {
  padding: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.p-r-0 {
  padding-right: 0 !important;
}

.p-r-5 {
  padding-right: 5px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.p-r-15 {
  padding-right: 15px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.p-r-25 {
  padding-right: 25px !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.p-l-0 {
  padding-left: 0 !important;
}

.p-l-5 {
  padding-left: 5px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.p-l-15 {
  padding-left: 15px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-l-25 {
  padding-left: 25px !important;
}


.m-0 {
  margin: 0 !important;
}

.m-5 {
  margin: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-r-0 {
  margin-right: 0 !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-r-15 {
  margin-right: 15px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.m-r-25 {
  margin-right: 25px !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.m-l-0 {
  margin-left: 0 !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

@primary-color: #7b1c9a;